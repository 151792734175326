<template>
  <div>
    <b-card>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="mt-1"
          @submit.prevent="handleSubmit(addClient)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-alert
                v-model="showMessage"
                dismissible
                :variant="messageType"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span
                    class="ml-25"
                    v-html="message"
                  />
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
              lg="12"
            >
              <div class="d-flex">
                <feather-icon
                  icon="UserIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  بيانات العميل
                </h4>
              </div>
            </b-col>
            <b-col cols="12">
              <hr class="my-2">
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <!-- Field: name -->
              <validation-provider
                #default="validationContext"
                vid="name"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="إسم العميل *"
                  label-for="name"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="name"
                    v-model="clientData.name"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <!-- Field: type -->
              <validation-provider
                #default="validationContext"
                vid="type"
                name="type"
                rules="required"
              >
                <b-form-group
                  label="نوع العميل *"
                  label-for="type"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="clientData.type"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="types"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="type"
                    @input="typeChanged"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <!-- Field: identity_card -->
              <validation-provider
                v-if="clientData.type === 'individuals'"
                #default="validationContext"
                vid="identity_card"
                name="identity_card"
                rules="required"
              >
                <b-form-group
                  label="رقم الهوية الوطنية *"
                  label-for="identity_card"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="identity-card"
                    v-model="clientData.identity_card"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <!-- Field: mobile -->
              <validation-provider
                #default="validationContext"
                vid="mobile"
                name="mobile"
                rules="required"
              >
                <b-form-group
                  label="رقم الجوال *"
                  label-for="mobile"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="mobile"
                    v-model="clientData.mobile"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <!-- Field: email -->
              <validation-provider
                #default="validationContext"
                vid="email"
                name="email"
                rules="required"
              >
                <b-form-group
                  label="البريد الإلكتروني *"
                  label-for="email"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="email"
                    v-model="clientData.email"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <!-- Field: commercial_register -->
              <validation-provider
                v-if="clientData.type !== 'individuals'"
                #default="validationContext"
                vid="commercial_register"
                name="commercial_register"
                rules="required"
              >
                <b-form-group
                  label="السجل التجاري *"
                  label-for="commercial_register"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="commercial-register"
                    v-model="clientData.commercial_register"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <!-- Field: logo -->
              <validation-provider
                #default="validationContext"
                vid="logo"
                name="logo"
              >
                <b-form-group
                  label="شعار العميل"
                  label-for="logo"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-file
                    id="logo"
                    v-model="clientData.logo"
                    accept="image/*, application/pdf"
                    browse-text="اختر شعار"
                    placeholder="اختر ملفا ..."
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="clientData.type !== 'individuals'">
            <b-col cols="12">
              <hr class="my-2">
            </b-col>
            <b-col
              cols="12"
              md="12"
              lg="12"
            >
              <div class="d-flex">
                <feather-icon
                  icon="UserIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  العنوان الوطني للعميل
                </h4>
              </div>
            </b-col>
            <b-col cols="12">
              <hr class="my-2">
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: building_number -->
              <validation-provider
                #default="validationContext"
                vid="building_number"
                name="building_number"
                
              >
                <b-form-group
                  label="رقم المبنى"
                  label-for="building_number"
                  :state="getValidationState(validationContext)"
                  descrition=""
                >
                  <b-form-input
                    id="building_number"
                    v-model="clientData.address.building_number"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-text :id="`address.building_number`">
                    هذا الحقل مطلوب إذا كان نوع العميل ليس أفراد
                  </b-form-text>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: street -->
              <validation-provider
                #default="validationContext"
                vid="street"
                name="street"
                
              >
                <b-form-group
                  label="الشارع"
                  label-for="street"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="street"
                    v-model="clientData.address.street"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-text :id="`address.street`">
                    هذا الحقل مطلوب إذا كان نوع العميل ليس أفراد
                  </b-form-text>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: city -->
              <validation-provider
                #default="validationContext"
                vid="city"
                name="city"
                
              >
                <b-form-group
                  label="المدينة"
                  label-for="city"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="clientData.address.city_id"
                    dir="rtl"
                    :options="cities"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="city"
                    @input="cityChanged"
                  />

                  <b-form-text :id="`address.city`">
                    هذا الحقل مطلوب إذا كان نوع العميل ليس أفراد
                  </b-form-text>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: district_id -->
              <validation-provider
                #default="validationContext"
                vid="district_id"
                name="district_id"
                
              >
                <b-form-group
                  label="الحي"
                  label-for="district_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="clientData.address.district_id"
                    dir="rtl"
                    :loading="isLoading"
                    :options="districts"
                    :reduce="val => val.value"
                    :disabled="districts.length == 0"
                    :clearable="false"
                    input-id="district_id"
                    @input="districtChanged"
                  />

                  <b-form-text :id="`address.district_id`">
                    هذا الحقل مطلوب إذا كان نوع العميل ليس أفراد
                  </b-form-text>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: postcode -->
              <validation-provider
                #default="validationContext"
                vid="postcode"
                name="postcode"
                
              >
                <b-form-group
                  label="الرمز البريدي"
                  label-for="postcode"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="postcode"
                    v-model="clientData.address.postcode"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-text :id="`address.postcode`">
                    هذا الحقل مطلوب إذا كان نوع العميل ليس أفراد
                  </b-form-text>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: additional_number -->
              <validation-provider
                #default="validationContext"
                vid="additional_number"
                name="additional_number"
                
              >
                <b-form-group
                  label="الرمز الاضافي"
                  label-for="additional_number"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="additional_number"
                    v-model="clientData.address.additional_number"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-text :id="`address.additional_number`">
                    هذا الحقل مطلوب إذا كان نوع العميل ليس أفراد
                  </b-form-text>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: short_national_address -->
              <validation-provider
                #default="validationContext"
                vid="short_national_address"
                name="short_national_address"
                
              >
                <b-form-group
                  label="العنوان الوطني المختصر"
                  label-for="short_national_address"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="short_national_address"
                    v-model="clientData.address.short_national_address"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-text :id="`address.short_national_address`">
                    هذا الحقل مطلوب إذا كان نوع العميل ليس أفراد
                  </b-form-text>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="clientData.type !== 'individuals'">
            <b-col cols="12">
              <hr class="my-2">
            </b-col>
            <b-col
              cols="12"
              md="12"
              lg="12"
            >
              <div class="d-flex">
                <feather-icon
                  icon="UserIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  بيانات مسؤول الاتصال
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn-sm"
                    variant="primary"
                    @click="addItem"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>إضافة</span>
                  </b-button>
                </h4>
              </div>
            </b-col>
            <b-col cols="12">
              <hr class="my-2">
            </b-col>
            <b-col
              cols="12"
              md="12"
              lg="12"
            >
              <b-row
                v-for="(item, index) in clientData.contacts"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <b-col
                  cols="12"
                  md="3"
                  lg="3"
                >
                  <!-- Field: name -->
                  <validation-provider
                    #default="validationContext"
                    :vid="`contacts.${index}.name`"
                    name="name"
                    rules="required"
                  >
                    <b-form-group
                      label="إسم المسؤول *"
                      :label-for="`contacts.${index}.name`"
                      :state="getValidationState(validationContext)"
                      d
                    >
                      <b-form-input
                        :id="`contacts.${index}.name`"
                        v-model="item.name"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-text :id="`contacts.${index}.name`">
                        هذا الحقل مطلوب إذا كان نوع العميل ليس أفراد
                      </b-form-text>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  lg="3"
                >
                  <!-- Field: email -->
                  <validation-provider
                    #default="validationContext"
                    :vid="`contacts.${index}.email`"
                    name="email"
                    rules="required"
                  >
                    <b-form-group
                      label="البريد الإلكتروني *"
                      :label-for="`contacts.${index}.email`"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-input
                        :id="`contacts.${index}.email`"
                        v-model="item.email"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-text :id="`contacts.${index}.email`">
                        هذا الحقل مطلوب إذا كان نوع العميل ليس أفراد
                      </b-form-text>

                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  lg="3"
                >
                  <!-- Field: mobile -->
                  <validation-provider
                    #default="validationContext"
                    :vid="`contacts.${index}.mobile`"
                    name="mobile"
                    rules="required"
                  >
                    <b-form-group
                      label="الجوال *"
                      :label-for="`contacts.${index}.mobile`"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-input
                        :id="`contacts.${index}.mobile`"
                        v-model="item.mobile"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-text :id="`contacts.${index}.mobile`">
                        هذا الحقل مطلوب إذا كان نوع العميل ليس أفراد
                      </b-form-text>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Remove Button -->
                <b-col
                  cols="12"
                  md="3"
                  lg="3"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>حدف</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                حفظ التغييرات
              </b-button>
              <b-button
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="resetForm()"
              >
                إعادة ضبط
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BLink,
  BFormFile,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BFormText,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { heightTransition } from '@core/mixins/ui/transition';
import clientStore from '../clientStore';

export default {
  components: {
    BFormFile,
    BButton,
    BLink,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormText,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin, heightTransition],
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
      isLoading: false,
      districts: [],
    };
  },
  methods: {
    addItem() {
      this.clientData.contacts.push({
        name: '',
        email: '',
        mobile: '',
      });
    },
    removeItem(index) {
      this.clientData.contacts.splice(index, 1);
    },
    cityChanged(city_id) {
      this.fetchDistricts(city_id);
      this.clientData.address.city_id = city_id;
    },
    districtChanged(district_id) {
      this.clientData.address.district_id = district_id;
    },
    typeChanged(type) {
      this.clientData.type = type;
    },
    fetchDistricts(city_id) {
      this.isLoading = true;
      this.$store
        .dispatch('client/fetchDistricts', { id: city_id })
        .then((response) => {
          const { data } = response.data;
          this.districts = data.map((district) => ({
            label: district.name_ar,
            value: district.id,
          }));
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    addClient() {
      console.log('addClient');
      this.$refs.refFormObserver
        .validate()
        .then(async (success) => {
          if (success) {
            store
              .dispatch('client/addClient', this.clientData)
              .then((response) => {
                this.$toast.success(response.data.message);
                router.push('/client').catch(() => {});
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status === 422) {
                    this.$toast.error(err.response.data.message);
                    const { message, errors } = err.response.data;
                    if (errors) {
                      const errorMessage = {
                        message: '',
                        type: 'danger',
                      };
                      Object.values(errors).forEach((error) => {
                        errorMessage.message += `${error[0]}<br>`;
                      });
                      this.displayMessage(errorMessage);
                    }
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: message,
                        text: message,
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                      },
                    });
                  } else {
                    this.$toast.error(err.response.data.error);
                  }
                }
                console.log(err);
              });
          } else {
            this.$toast.error('البيانات المقدمة غير صحيحة');
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              this.$refs.refFormObserver.setErrors(err.response.data.errors);
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },
  setup() {
    const CLIENT_STORE_MODULE_NAME = 'client';

    // Register client
    if (!store.hasModule(CLIENT_STORE_MODULE_NAME)) store.registerModule(CLIENT_STORE_MODULE_NAME, clientStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_STORE_MODULE_NAME);
    });
    const blankClientData = {
      name: '',
      type: '',
      identity_card: '',
      mobile: '',
      email: '',
      commercial_register: '',
      address: {},
      contacts: [{}],
    };
    const cities = ref([]);
    store
      .dispatch('client/fetchCities')
      .then((response) => {
        const { data } = response.data;
        cities.value = data.map((c) => ({
          label: c.name_ar,
          value: c.id,
          ...c,
        }));
      });

    const types = ref([]);
    store
      .dispatch('client/fetchClientOption', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { clientTypes } = response.data;
        types.value = clientTypes;
      });

    const clientData = ref(JSON.parse(JSON.stringify(blankClientData)));
    const resetclientData = () => {
      clientData.value = JSON.parse(JSON.stringify(blankClientData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetclientData);

    return {
      cities,
      types,
      clientData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
